import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/circleci/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
import './getstarted.scss';
import { Contrast, NavigationCard } from '@entur/layout';
import { NumberedList, ListItem, Paragraph } from '@entur/typography';
import tripSearch from './images-getstarted/trip-search.svg';
import salesMain from './images-getstarted/sales-main.svg';
import salesArrow from './images-getstarted/sales-arrow.svg';
import salesArrowname from './images-getstarted/sales-arrowname.svg';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const ExpandablePanel = makeShortcode("ExpandablePanel");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1 {...{
      "id": "get-started"
    }}>{`Get Started`}</h1>
    <h2 {...{
      "id": "departure-boards-for-individual-stops"
    }}>{`Departure Boards for Individual Stops`}</h2>
    <p>{`A common use of the Entur APIs is to show the next departures from a certain stop place. You can use this to create you own departure board similar to that on `}<a parentName="p" {...{
        "href": "https://entur.no/nearby-stop-place-detail?id=NSR:StopPlace:59872"
      }}>{`entur.no`}</a>{`, `}<a parentName="p" {...{
        "href": "https://tavla.entur.no"
      }}>{`Tavla`}</a>{` or on the different bus stop displays around town.
We'll show you how with some simple examples.`}</p>
    <p><strong parentName="p">{`Goal`}</strong>{`: display the next 5 train departures from Oslo S (the Oslo Central Station).`}</p>
    <p>{`First of all, we need to find the Stop Place ID for Oslo S. We can use the search field on the `}<a parentName="p" {...{
        "href": "/pages-geocoder-intro"
      }}>{`Geocoder page`}</a>{` to look it up. We search for "Oslo S" and find the ID "NSR:StopPlace:59872". Great!`}</p>
    <p>{`Now, let's use the Journey Planner API to find us some departures.`}</p>
    <ExpandablePanel title="Using JavaScript, but with your own GraphQL query" mdxType="ExpandablePanel">
      <p>
    We can define a GraphQL query using the online GraphQL editor tool for
    Journey Planner. Here is an example that should serve our needs:{' '}
    <a href="https://api.entur.io/journey-planner/v2/ide/?query=%7B%0A%20%20stopPlace%28id%3A%20%22NSR%3AStopPlace%3A59872%22%29%20%7B%0A%20%20%20%20name%0A%20%20%20%20id%0A%20%20%20%20estimatedCalls%28numberOfDepartures%3A%205%2C%20whiteListedModes%3A%20%5Brail%5D%29%20%7B%0A%20%20%20%20%20%20expectedDepartureTime%0A%20%20%20%20%20%20aimedDepartureTime%0A%20%20%20%20%20%20destinationDisplay%20%7B%0A%20%20%20%20%20%20%20%20frontText%0A%20%20%20%20%20%20%7D%0A%20%20%20%20%20%20serviceJourney%20%7B%0A%20%20%20%20%20%20%20%20line%20%7B%0A%20%20%20%20%20%20%20%20%20%20publicCode%0A%20%20%20%20%20%20%20%20%20%20transportMode%0A%20%20%20%20%20%20%20%20%7D%0A%20%20%20%20%20%20%7D%0A%20%20%20%20%7D%0A%20%20%7D%0A%7D%0A">
        stopPlace query
    </a>
    .
      </p>
      <p>
    Now that we have defined our query, all we need to do is to make the HTTP
    request. We will use the{' '}
    <a href="https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API">
        Fetch API
    </a>{' '}
    in our example. This is built-in in all modern browsers.
      </p>
      <pre>
    <code>
        {`
const query = \`{
  stopPlace(id: "NSR:StopPlace:59872") {
    name
    id
    estimatedCalls(numberOfDepartures: 5, whiteListedModes: [rail]) {
      expectedDepartureTime
      aimedDepartureTime
      destinationDisplay {
        frontText
      }
      serviceJourney {
        line {
          publicCode
          transportMode
        }
      }
    }
  }
}
\`
fetch('https://api.entur.io/journey-planner/v2/graphql', {
    method: 'POST',
    headers: {
        // Replace this with your own client name:
        'ET-Client-Name': 'awesomecompany-awesomeapp',
        'Content-Type': 'application/json'
    },
    body: JSON.stringify({ query }),
})
    .then(res => res.json())
    .then(stopPlaceData => console.log(stopPlaceData))
`}
    </code>
      </pre>
      <p>Try copying the above example into your browser's console and execute it!</p>
    </ExpandablePanel>
    <h2 {...{
      "id": "trip-search"
    }}>{`Trip Search`}</h2>
    <p>{`The following diagram illustrates trip search in the Entur application. Initially, the user searches for stop places from the Geocoder API before the full trip search with given parameters
(start point, destination, date, trip alternatives, etc.) is started.`}</p>
    <p>{`The travel search is done through the Offers API's `}<inlineCode parentName="p">{`/search/graphql`}</inlineCode>{` endpoint. This endpoint works just like the JourneyPlanner API, but Offers handles the response like so:`}</p>
    <ul>
      <li parentName="ul">{`Stores the resulting trip patterns in a database for about 20 min`}</li>
      <li parentName="ul">{`Puts a unique `}<inlineCode parentName="li">{`id`}</inlineCode>{` on each of the trip patterns`}</li>
      <li parentName="ul">{`Starts fetching pricing data for 1 adult for each of these trip patterns`}</li>
    </ul>
    <p>{`When the client has received the trip patterns, it can request offers (prices) for each of these by their ID using the `}<inlineCode parentName="p">{`/search/trip/trip-pattern/{id}`}</inlineCode>{` endpoints.`}</p>
    <div className="getstarted-trip">
    <div className="getstarted-trip__info">
        <Paragraph mdxType="Paragraph">
            For each travel option products are fetched with a rest-request from
            Backend for Frontend. The answers are streamed to the client.
        </Paragraph>
    </div>
    <NavigationCard href="/pages-geocoder-intro" className="getstarted-trip__geocoder-navigation-card" title="Geocoder" mdxType="NavigationCard" />
    <div className="getstarted-trip__main">
        <img src={tripSearch} alt="Trip search" />
    </div>
    <NavigationCard href="/pages-offers-docs-intro" className="getstarted-trip__offers-navigation-card" title="Offers" mdxType="NavigationCard" />
    </div>
    <h2 {...{
      "id": "sale"
    }}>{`Sale`}</h2>
    <p>{`The following diagram illustrates a sale in the Entur application. When the trip search flow is completed, a sale may begin. The user must be logged in to purchase an offer because the endpoints in this flow demand authentication. Notice that each service demands several requests. The requests are listed in the diagram below.`}</p>
    <Contrast mdxType="Contrast">
    <div className="getstarted-sales-info">
        <Paragraph mdxType="Paragraph">
            The diagram illustrates the process given that the travel search is
            completed and that the client wishes to purchase an offer based on
            offerId and offerConfigurations.
        </Paragraph>
    </div>
    </Contrast>
    <div className="getstarted-sales-grid">
    <div className="getstarted-sales-main">
      <img src={salesMain} alt="Sales" />
    </div>
    <NavigationCard href="/pages-sales-guides#create-order" className="getstarted-sales-create-order" title="Sales" mdxType="NavigationCard">Create order</NavigationCard>
    <NavigationCard href="/pages-sales-guides#create-payment " className="getstarted-sales-create-payment" title="Sales" mdxType="NavigationCard">Pay order</NavigationCard>
    <NavigationCard href="/pages-sales-guides#generate-and-distribute-ticket-distributions" className="getstarted-sales-distribute-ticket" title="Sales" mdxType="NavigationCard">Distribute ticket</NavigationCard>
    <div className="getstarted-sales-arrow">
      <img src={salesArrow} alt="Sales" />
    </div>
    <div className="getstarted-sales-arrowname">
      <img src={salesArrowname} alt="Sales" />
    </div>
    <NavigationCard href="pages-nod-intro" className="getstarted-sales-nod" title="NOD" mdxType="NavigationCard"><br /></NavigationCard>
      <div className="getstarted-sales-create-order-info">
  <Paragraph mdxType="Paragraph">
    Create an order in three steps:
  </Paragraph>
    <NumberedList mdxType="NumberedList">
      <ListItem mdxType="ListItem">Create an empty order</ListItem>
      <ListItem mdxType="ListItem">Get the order</ListItem>
      <ListItem mdxType="ListItem">Add an offer to the order by sending offerId and offerConfigurations</ListItem>
    </NumberedList>
      </div>
      <div className="getstarted-sales-create-payment-info">
  <Paragraph mdxType="Paragraph">
    A sale comprises several steps:
  </Paragraph>
      <NumberedList mdxType="NumberedList">
        <ListItem mdxType="ListItem">Create Payment</ListItem>
        <ListItem mdxType="ListItem">Create a Payment Transaction</ListItem>
        <ListItem mdxType="ListItem">Open NETS-terminal (Optionally: use a saved card)</ListItem>
        <ListItem mdxType="ListItem">Complete payment</ListItem>
      </NumberedList>
      </div>
      <div className="getstarted-sales-distribute-ticket-info">
  <Paragraph mdxType="Paragraph">
  A ticket is created in two steps:
  </Paragraph>
    <NumberedList mdxType="NumberedList">
      <ListItem mdxType="ListItem">Create ticket</ListItem>
       <ListItem mdxType="ListItem">Distribute ticket to National Order Database (NOD)</ListItem>
    </NumberedList>
      </div>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      